import { useEffect } from 'react';
import { AuthService } from '../services/Auth.service';
import { authActions } from '../state/authState';
import { useGlobalState } from './useGlobalState';

export function useAuthCheck() {
  const { dispatch } = useGlobalState();

  useEffect(() => {
    (async () => {
      try {
        dispatch(authActions.setLoading(true));
        const user = await AuthService.getAuthenticatedUser();
        dispatch(authActions.setIsTokenValid(true));
        dispatch(authActions.setUser(user));
      } catch (error) {
        console.error(error);
        dispatch(authActions.setIsTokenValid(false));
      } finally {
        dispatch(authActions.setLoading(false));
      }
    })();
  }, []);
}
