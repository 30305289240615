import React, { useReducer, useContext, useMemo } from 'react';
import {
  accountsReducerInitialState,
  accountsReducer,
} from '../state/accountsState';
import { authReducer, authReducerInitialState } from '../state/authState';
import {
  migrationReducer,
  migrationReducerInitialState,
} from '../state/migrationState';
import { uiReducer, uiReducerInitialState } from '../state/uiState';

const combineReducers = slices => (state, action) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );

const initialState = {
  accounts: accountsReducerInitialState,
  auth: authReducerInitialState,
  ui: uiReducerInitialState,
  migration: migrationReducerInitialState,
};

const combinedReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  ui: uiReducer,
  migration: migrationReducer,
  test: (state, action) => {
    if (process.env.REACT_ENV) {
      // eslint-disable-next-line no-console
      console.log(action);
    }
    return state;
  },
});

export const StateContext = React.createContext(initialState);

export const StateProvider = ({ children, initialValue = undefined }) => {
  const [state, dispatch] = useReducer(
    combinedReducer,
    initialValue || initialState
  );

  return (
    <StateContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useGlobalState = () => useContext(StateContext);
export const useSelector = (selectorFunction = () => {}) => {
  const state = useContext(StateContext);
  const data = selectorFunction(state);
  return useMemo(() => data, [data ? JSON.stringify(data) : data]);
};
export const useDispatch = () => {
  const { dispatch } = useContext(StateContext);
  return useMemo(() => dispatch, []);
};
