import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider } from '@shopify/app-bridge-react';
import { useAppBridgeConfig } from '../hooks/useAppBridgeConfig';
import { ErrorScreen } from '../components/ErrorScreen';
/**
 * A component to configure App Bridge.
 * @desc A thin wrapper around AppBridgeProvider that provides the following capabilities:
 *
 * 1. Ensures that navigating inside the app updates the host URL.
 * 2. Configures the App Bridge Provider, which unlocks functionality provided by the host.
 *
 * See: https://shopify.dev/apps/tools/app-bridge/react-components
 */
export default function AppBridgeProvider({ children }) {
  const location = useLocation();
  const history = useMemo(() => ({ replace: () => {} }), []);
  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );
  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const appBridgeConfig = useAppBridgeConfig();
  if (!appBridgeConfig.apiKey || !appBridgeConfig.host) {
    const bannerProps = !appBridgeConfig.apiKey
      ? {
          title: 'Missing Shopify API Key',
          description: <>Shopify Configuration Error! Something went wrong</>,
        }
      : {
          title: 'Missing host query argument',
          description: (
            <>
              Your app can only load if the URL has a <b>host</b> argument.
              Please ensure that it is set, or access your app using the
              Partners Dashboard <b>Test your app</b> feature
            </>
          ),
        };
    return <ErrorScreen bannerProps={bannerProps} />;
  }

  return (
    <Provider config={appBridgeConfig} router={router}>
      {children}
    </Provider>
  );
}
