import React from 'react';
import { ErrorScreen } from './ErrorScreen';

export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    console.error(error);
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      bannerProps: {
        description: <>Please refresh and try again</>,
        title: 'Looks like we ran into an issue.',
      },
      hasError: false,
    };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorScreen bannerProps={this.state.bannerProps} />;
    }
    return this.props.children;
  }
}
