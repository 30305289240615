import React, { useEffect } from 'react';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { Redirect } from '@shopify/app-bridge/actions';
import createApp from '@shopify/app-bridge';
import AppRoutes from './AppRoutes';
import AppBridgeProvider from './providers/AppBridgeProvider';
import { useAuthCheck } from './hooks/useAuthCheck';
import { CenterInPage } from './components/CenterInPage';
import { LoadingSpinner } from './components/LoadingSpinner';
import { useAppBridgeConfig } from './hooks/useAppBridgeConfig';
import { useGlobalState } from './hooks/useGlobalState';
import { RetailService } from './services/Retail.service';
import { Storage } from './utils/Storage';
import * as Constants from './utils/Constants';
import OAuthHandler from './pages/oauth-handler/OAuthHandler';
import './index.css';

function RedirectToConnectorOauth() {
  const isSSOPage = React.useMemo(
    () =>
      window.location.pathname.includes('/auth/sso') ||
      window.location.pathname.includes('/app/oauth-handler'),
    []
  );
  const appBridgeConfig = useAppBridgeConfig();

  useEffect(() => {
    if (isSSOPage) return;
    (async () => {
      try {
        const apiKeyData = await RetailService.getApiKey();
        const authenticationUrl =
          window.location.origin +
          `/oauth/shopify-stocky-connector/get-auth${location.search}`;
        await Storage.set(Constants.API_KEY, apiKeyData.clientId);
        if (!isShopifyEmbedded()) {
          window.location.assign(authenticationUrl);
        } else {
          const app = createApp({
            ...appBridgeConfig,
            apiKey: apiKeyData.clientId,
            forceRedirect: false,
          });
          const redirect = Redirect.create(app);
          redirect.dispatch(Redirect.Action.REMOTE, authenticationUrl);
        }
      } catch (error) {
        console.error(error);
        setTimeout(() => window.location.reload(), 1000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenterInPage>
      <span>Authenticating</span>
      {'  '}
      <LoadingSpinner size='small' />
    </CenterInPage>
  );
}

function App() {
  const isSSOPage = React.useMemo(
    () => window.location.pathname.includes('/auth/sso'),
    []
  );

  const isOAuthPage = React.useMemo(
    () => window.location.pathname.includes('/app/oauth-handler'),
    []
  );

  useAuthCheck();

  const {
    auth: { loading, isTokenValid },
  } = useGlobalState();

  if (loading) {
    return (
      <CenterInPage>
        <LoadingSpinner />
      </CenterInPage>
    );
  }

  if (isOAuthPage) {
    return <OAuthHandler />;
  }

  if (isTokenValid || isSSOPage) {
    return (
      <AppBridgeProvider>
        <AppRoutes />
      </AppBridgeProvider>
    );
  }
  return <RedirectToConnectorOauth />;
}

export default App;
