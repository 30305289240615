export const migrationReducerInitialState = {
  migration: null,
};
export function migrationReducer(state, action) {
  switch (action.type) {
    case 'migration/SET_DETAILS':
      return {
        ...state,
        migration: action.payload.migration,
      };
    case 'migration/SET_STOCKY_API_KEY':
      return {
        ...state,
        migration: {
          ...state.migration,
          stockyApiKey: action.payload.stockyApiKey,
        },
      };
    case 'migration/SET_CUSTOMER_UPLOADED':
      return {
        ...state,
        migration: {
          ...state.migration,
          customerMigrateJob: {
            ...state.migration.customerMigrateJob,
            fileUpload: action.payload.uploaded,
          },
        },
      };
    case 'migration/SET_VENDOR_UPLOADED':
      return {
        ...state,
        migration: {
          ...state.migration,
          vendorMigrateJob: {
            ...state.migration.vendorMigrateJob,
            fileUpload: action.payload.uploaded,
          },
        },
      };
    case 'migration/SET_PRODUCT_UPLOADED':
      return {
        ...state,
        migration: {
          ...state.migration,
          itemMigrateJob: {
            ...state.migration.itemMigrateJob,
            fileUpload: action.payload.uploaded,
          },
        },
      };
    default:
      return state;
  }
}

const setMigrationDetails = migration => {
  if (migration?.customerMigrateJob?.exceptionFileLink) {
    migration.customerMigrateJob.fileUpload = false;
  }
  if (migration?.vendorMigrateJob?.exceptionFileLink) {
    migration.vendorMigrateJob.fileUpload = false;
  }
  if (migration?.itemMigrateJob?.exceptionFileLink) {
    migration.itemMigrateJob.fileUpload = false;
  }
  return {
    type: 'migration/SET_DETAILS',
    payload: {
      migration,
    },
  };
};

const setCustomerUploaded = (uploaded = true) => {
  return {
    type: 'migration/SET_CUSTOMER_UPLOADED',
    payload: {
      uploaded,
    },
  };
};

const setProductUploaded = (uploaded = true) => {
  return {
    type: 'migration/SET_PRODUCT_UPLOADED',
    payload: {
      uploaded,
    },
  };
};

const setVendorUploaded = (uploaded = true) => {
  return {
    type: 'migration/SET_VENDOR_UPLOADED',
    payload: {
      uploaded,
    },
  };
};

const setStockyApiKey = stockyApiKey => {
  return {
    type: 'migration/SET_STOCKY_API_KEY',
    payload: {
      stockyApiKey,
    },
  };
};

export const migrationActions = {
  setMigrationDetails,
  setProductUploaded,
  setVendorUploaded,
  setCustomerUploaded,
  setStockyApiKey,
};

const selectMigrationState = state => state.migration.migration;
const selectMigrationId = state => state.migration?.migration?._id;
const selectMigrationShopId = state => selectMigrationState(state)?.shopId;

const selectCustomerUploaded = state =>
  selectMigrationState(state)?.customerMigrateJob?.fileUpload;

const selectProductUploaded = state =>
  selectMigrationState(state)?.itemMigrateJob?.fileUpload;

const selectVendorUploaded = state =>
  selectMigrationState(state)?.vendorMigrateJob?.fileUpload;

const selectStockyApiKey = state =>
  selectMigrationState(state)?.stockyApiKey || '';

const selectIsMigrationRunning = state =>
  !selectMigrationState(state)?.skipMigration &&
  selectMigrationState(state)?.enableForMigration === true &&
  selectMigrationState(state)?.status !== 'migrationcomplete';

const selectIsMigrationComplete = state => {
  const migration = selectMigrationState(state);
  return (
    migration?.skipMigration ||
    (migration?.status === 'migrationcomplete' &&
      !selectMigrationHasErrors(state))
  );
};

const selectIsMigrationSetupStarted = state => {
  return (
    selectCustomerUploaded(state) ||
    selectVendorUploaded(state) ||
    selectProductUploaded(state) ||
    selectStockyApiKey(state) ||
    selectMigrationHasErrors(state)
  );
};

const selectMigrationHasErrors = state => {
  const migration = selectMigrationState(state);
  const individualJobsCompleteWithError =
    (migration?.customerMigrateJob?.jobComplete &&
      migration?.customerMigrateJob?.exceptionFileLink) ||
    (migration?.itemMigrateJob?.jobComplete &&
      migration?.itemMigrateJob?.exceptionFileLink) ||
    (migration?.vendorMigrateJob?.jobComplete &&
      migration?.vendorMigrateJob?.exceptionFileLink);
  return individualJobsCompleteWithError && !migration?.skipMigration;
};

const selectMigrationErrorFiles = state => {
  const migration = selectMigrationState(state);
  return {
    customer: migration?.customerMigrateJob?.exceptionFileLink,
    item: migration?.itemMigrateJob?.exceptionFileLink,
    vendor: migration?.vendorMigrateJob?.exceptionFileLink,
  };
};

const selectMigrationJobCompleteStatus = state => {
  const migration = selectMigrationState(state);
  return {
    customer: migration?.customerMigrateJob?.jobComplete,
    inventory: migration?.itemMigrateJob?.jobComplete,
    vendor: migration?.vendorMigrateJob?.jobComplete,
  };
};

export const migrationSelectors = {
  selectMigrationShopId,
  selectMigrationId,
  selectMigrationState,
  selectCustomerUploaded,
  selectProductUploaded,
  selectVendorUploaded,
  selectStockyApiKey,
  selectIsMigrationRunning,
  selectIsMigrationComplete,
  selectMigrationHasErrors,
  selectMigrationErrorFiles,
  selectIsMigrationSetupStarted,
  selectMigrationJobCompleteStatus,
};

export const migrationFactory = (data = {}) => {
  return Object.assign({}, migrationReducerInitialState, data);
};
