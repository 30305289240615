/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Banner, Layout, Page } from '@shopify/polaris';
import React, { useEffect } from 'react';
import { useGlobalState } from '../hooks/useGlobalState';
import { AnalyticsService } from '../services/Analytics.service';
import { GLOBAL_ERROR_GENERATED } from '../utils/Constants';

export const ErrorScreen = props => {
  const host = React.useMemo(
    () => new URLSearchParams(location.search).get('shop'),
    []
  );
  const {
    accounts: { shopifyAccount },
  } = useGlobalState();
  const { bannerProps } = props;
  useEffect(() => {
    (async () => {
      try {
        await AnalyticsService.trackAnalytics(
          GLOBAL_ERROR_GENERATED,
          shopifyAccount?.shopId,
          { message: bannerProps.title, host }
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })();
  });
  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <div
            css={css`
              margin-top: 100px;
            `}
          >
            <Banner status='critical' title={bannerProps.title}>
              {bannerProps.description}
            </Banner>
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
