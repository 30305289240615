import * as Sentry from '@sentry/react';

export const uiReducerInitialState = {
  page: 0,
  token: null,
  progress: {
    // Page 1
    '1_1_configureSalesTax': false,
    '1_2_singleUserMode': false,
    '1_3_manageApps': false,
    '1_4_addApplication': false,
    '1_5_generateToken': false,
    '1_6_grantPermission': false,
    '1_7_enterToken': false,
    host: '',
    distinctId: '',
  },
};
export function uiReducer(state = uiReducerInitialState, action) {
  switch (action.type) {
    case 'ui/SET_STATE':
      Sentry.setContext('state', action.payload.state);

      if (!action.payload.state) {
        Sentry.setContext('state', state);

        return state;
      }
      Sentry.setContext('state', action.payload.state);
      return {
        ...state,
        page: action.payload.state.page,
        progress: action.payload.state.progress,
        token: action.payload.state.token,
      };
    case 'ui/SET_TOKEN':
      Sentry.setContext('state', action.payload.state);

      return {
        ...state,
        token: action.payload.state,
      };
    default:
      return state;
  }
}

const setUpdatedState = state => {
  return {
    type: 'ui/SET_STATE',
    payload: {
      state,
    },
  };
};

const setUpdateToken = state => {
  return {
    type: 'ui/SET_TOKEN',
    payload: {
      state,
    },
  };
};
export const uiActions = {
  setUpdatedState,
  setUpdateToken,
};
