import axios from 'axios';
import * as Sentry from '@sentry/react';
import * as Constants from '../utils/Constants';
import { Storage } from './Storage';

export const AuthenticatedApi = axios.create({
  baseURL: '/',
});

AuthenticatedApi.interceptors.request.use(
  async config => {
    const headers = config.headers;
    if (!headers) {
      config.headers = {};
    }
    config.headers.Authorization =
      'Session ' + (await Storage.get(Constants.SP_AUTH_TOKEN));
    config.headers['shopify-shop-url'] =
      new URLSearchParams(location.search).get('shop') ||
      window.SHOPIFY_DEV_HOST;
    return config;
  },
  error => Promise.reject(error)
);

function isAuthServiceLogoutResponse(response) {
  // eslint-disable-next-line no-console
  console.log(response.status);
  if (response?.status === 401) {
    return true;
  }

  return false;
}

AuthenticatedApi.interceptors.response.use(
  response => {
    Sentry.addBreadcrumb({
      category: 'api',
      message:
        'URL: ' + response.config.url + ' Response Code: ' + response.status,
      level: 'info',
    });
    return Promise.resolve(response);
  },
  error => {
    Sentry.addBreadcrumb({
      category: 'api',
      message:
        'URL: ' +
        error?.response?.config?.url +
        ' Response Code: ' +
        error?.response?.status +
        ' Data: ' +
        JSON.stringify(error?.response?.data || {}),
      level: 'error',
    });
    switch (error.response.status) {
      case 0:
      case 401:
      case 503:
      case 504:
        break;
      default:
        Sentry.captureException(JSON.stringify(error.response));
    }
    if (
      error &&
      error.response &&
      isAuthServiceLogoutResponse(error.response)
    ) {
      window.location.assign(window.location.origin + window.location.search);
    }
    return Promise.reject(error);
  }
);

export const Api = axios.create({
  baseURL: '/',
});
