/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Route, Navigate, Routes, Outlet } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { LoadingSpinner } from './components/LoadingSpinner';

const SummaryAutoSyncDetails = lazy(() =>
  import('./pages/summary-page/SummaryAutoSyncDetails')
);
const SummaryPage = lazy(() => import('./pages/summary-page'));
const SummaryDetails = lazy(() =>
  import('./pages/summary-page/SummaryDetails')
);
const ConnectSystem = lazy(() => import('./pages/connect'));
const EditSettings = lazy(() => import('./pages/edit-settings'));
const QBDConnect = lazy(() => import('./pages/connect-qbd'));
const SSOPage = lazy(() => import('./pages/sso'));
const OAuthHandler = lazy(() => import('./pages/oauth-handler/OAuthHandler'));
const QBPOSMigrationSetup = lazy(() => import('./pages/qbpos-migration-setup'));
const QBPOSMigrationGuide = lazy(() => import('./pages/qbpos-migration-guide'));
const QBPOSMigrationMapping = lazy(() =>
  import('./pages/qbpos-migration-mapping')
);
const MigrationInProgress = lazy(() => import('./pages/migration-in-progress'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function AppRoutes() {
  return (
    <Suspense
      fallback={
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <LoadingSpinner />
        </div>
      }
    >
      <SentryRoutes>
        <Route element={<SSOPage />} path='auth/sso' />
        <Route element={<SummaryPage />} path='/summary' />
        <Route element={<EditSettings />} path='/edit-settings' />
        <Route element={<SummaryDetails />} path='/summary/integration' />
        <Route
          element={<SummaryAutoSyncDetails />}
          path='/summary/integration/auto-sync'
        />
        <Route element={<Outlet />} path='app'>
          <Route element={<OAuthHandler />} path='oauth-handler' />
          <Route element={<ConnectSystem />} path='connect' />
          <Route element={<QBDConnect />} path='connect/qbd/:stepNumber' />
        </Route>
        <Route element={<Outlet />} path='qbpos'>
          <Route element={<QBPOSMigrationSetup />} path='migration-setup' />
          <Route element={<QBPOSMigrationMapping />} path='migration-mapping' />
          <Route element={<QBPOSMigrationGuide />} path='migration-guide' />
          <Route
            element={<MigrationInProgress />}
            path='migration-in-progress'
          />
        </Route>
        <Route
          element={
            <Navigate
              replace
              to={{ pathname: '/app/connect', search: location.search }}
            />
          }
          path='*'
        />
      </SentryRoutes>
    </Suspense>
  );
}
