export const SP_AUTH_TOKEN = 'auth_token';
export const API_KEY = 'api_key';
export const OAUTH_SUCCESS_EVENT = 'oauth_success';
export const OAUTH_SUCCESS_EVENT_MESSAGE = 'Oauth Is SuccessFull';
export const OAUTH_FAILED_EVENT = 'oauth_failed';
export const OAUTH_FAILED_EVENT_MESSAGE = 'Oauth Failed';
export const MIGRATION_GET_STARTED = 'migration_get_started';
export const MIGRATION_GET_STARTED_MESSAGE =
  'Migration Get Started Button was Clicked';
export const INVENTORY_UPLOAD_SUCCESS = 'inventory_upload_success';
export const INVENTORY_UPLOAD_SUCCESS_MESSAGE = 'Inventory file was uploaded';
export const CUSTOMER_UPLOAD_SUCCESS = 'customer_upload_success';
export const CUSTOMER_UPLOAD_SUCCESS_MESSAGE = 'Customer file was uploaded';
export const VENDOR_UPLOAD_SUCCESS = 'vendor_upload_success';
export const VENDOR_UPLOAD_SUCCESS_MESSAGE = 'Vendor file was uploaded';

export const INVENTORY_UPLOAD_ERROR = 'inventory_upload_error';
export const INVENTORY_UPLOAD_ERROR_MESSAGE = 'Inventory file uploading error';
export const CUSTOMER_UPLOAD_ERROR = 'customer_upload_error';
export const CUSTOMER_UPLOAD_ERROR_MESSAGE = 'Customer file uploading error';
export const VENDOR_UPLOAD_ERROR = 'vendor_upload_error';
export const VENDOR_UPLOAD_ERROR_MESSAGE = 'Vendor file uploading error';

export const MIGRATION_STOCKY_KEY_SUCCESS = 'migration_stocky_key_success';
export const MIGRATION_STOCKY_KEY_SUCCESS_MESSAGE =
  'Stocky key was inserted succesfully in migration';
export const MIGRATION_PROCEEDED = 'migration_proceeded';
export const MIGRATION_PROCEEDED_MESSAGE =
  'Migration Proceeded after next button click on file upload page ';

export const FINAL_MIGRATION_FINISHED = 'final_migration_finished';
export const FINAL_MIGRATION_FINISHED_MESSAGE = 'Migration finished finally ';

export const MIGRATION_UPLOAD_FILE_SKIPPED = 'migration_upload_file_skipped';
export const MIGRATION_UPLOAD_FILE_SKIPPED_MESSAGE =
  'Migration Proceeded after skipping warning to upload all files';

export const MIGRATION_EXCEPTION_FILE_SKIPPED =
  'migration_exception_file_skipped';
export const MIGRATION_EXCEPTION_FILE_SKIPPED_MESSAGE =
  'Migration Proceeded after skipping file from exception screen';

export const MIGRATION_STARTED = 'started_migration';
export const MIGRATION_STARTED_MESSAGE = 'Started migration on button click';

export const INTEGRATION_START_BUTTON_CLICK = 'integration_start_button_click';
export const INTEGRATION_START_BUTTON_CLICK_MESSAGE =
  'start integration button clicked';

export const INTEGRATION_FIRST_STEP =
  'integration_without_migration_getting_started';
export const INTEGRATION_FIRST_STEP_MESSAGE =
  'Doing integration without migration';
export const COMPLETED_TAX_SETUP = 'Completed_tax_setup';
export const COMPLETED_TAX_SETUP_MESSAGE =
  'Next Button clicked on Setup tax preferences Page ';

export const COMPLETED_TOKEN_SETUP = 'Completed_token_setup';
export const COMPLETED_TOKEN_SETUP_MESSAGE =
  'Next Button clicked on Token generation page';

export const INTEGRATION_STOCKY_KEY_SUCCESS = 'Integration_stocky_key_success';
export const INTEGRATION_STOCKY_KEY_SUCCESS_MESSAGE =
  'Entered Stocky API key correctly (Integration)';
export const CHART_OF_ACCOUNT_FETCHING_ERROR = 'ChartofAcc_fetching_error';
export const CHART_OF_ACCOUNT_FETCHING_ERROR_MESSAGE =
  'Error generated on chart of acc mapping screen ';
export const INTEGRATION_CREATED_BUTTON = 'integration_created_button';
export const INTEGRATION_CREATED_BUTTON_MESSAGE =
  'Created integration process on button click';
export const INTEGRATION_EDITED_BUTTON = 'integration_edited_button';
export const INTEGRATION_EDITED_BUTTON_MESSAGE =
  'Edited integration on button click';
export const INTEGRATION_ERROR_GENERATED = 'integration_error_generated';
export const GLOBAL_ERROR_GENERATED = 'app_global_error';

export const requiredFileHeaders = {
  customer: {
    headers: [
      'Company',
      'Last Name',
      'First Name',
      'Street',
      'Street2',
      'City',
      'State',
      'ZIP',
      'Phone 1',
      'EMail',
      'QB ListID',
      'Customer Type',
    ],
  },
  inventory: {
    headers: [
      'Item Number',
      'Item Name',
      'Item Description',
      'Attribute',
      'Size',
      'Average Unit Cost',
      'Regular Price',
      'Vendor Name',
      'Qty 1',
      'QB ListID',
    ],
  },
  vendor: {
    headers: [
      'Company',
      'Last Name',
      'First Name',
      'Street',
      'Street2',
      'City',
      'State',
      'ZIP',
      'Phone 1',
      'EMail',
      'QB ListID',
      'Vendor Code',
    ],
  },
};

export const timeOptions = [
  {
    label: '12:00 - 12:30',
    value: '12:00:000',
  },
  {
    label: '12:30 - 01:00',
    value: '12:30:000',
  },
  {
    label: '01:00 - 01:30',
    value: '1:00:000',
  },
  {
    label: '01:30 - 02:00',
    value: '1:30:000',
  },
  {
    label: '02:00 - 02:30',
    value: '2:00:000',
  },
  {
    label: '02:30 - 03:00',
    value: '2:30:000',
  },
  {
    label: '03:00 - 03:30',
    value: '3:00:000',
  },
  {
    label: '03:30 - 04:00',
    value: '3:30:000',
  },
  {
    label: '04:00 - 04:30',
    value: '4:00:000',
  },
  {
    label: '04:30 - 05:00',
    value: '4:30:000',
  },
  {
    label: '05:00 - 05:30',
    value: '5:00:000',
  },
  {
    label: '05:30 - 06:00',
    value: '5:30:000',
  },
  {
    label: '06:00 - 06:30',
    value: '6:00:000',
  },
  {
    label: '06:30 - 07:00',
    value: '6:30:000',
  },
  {
    label: '07:00 - 07:30',
    value: '7:00:000',
  },
  {
    label: '07:30 - 08:00',
    value: '7:30:000',
  },
  {
    label: '08:00 - 08:30',
    value: '8:00:000',
  },
  {
    label: '08:30 - 09:00',
    value: '8:30:000',
  },
  {
    label: '09:00 - 09:30',
    value: '9:00:000',
  },
  {
    label: '09:30 - 10:00',
    value: '9:30:000',
  },
  {
    label: '10:00 - 10:30',
    value: '10:00:000',
  },
  {
    label: '10:30 - 11:00',
    value: '10:30:000',
  },
  {
    label: '11:00 - 11:30',
    value: '11:00:000',
  },
  {
    label: '11:30 - 12:00',
    value: '11:30:000',
  },
];
export const formatOptions = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
];
