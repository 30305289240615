import { useState } from 'react';
import * as Constants from '../utils/Constants';
import { Storage } from '../utils/Storage';

export function useAppBridgeConfig() {
  const [appBridgeConfig] = useState(() => {
    const host =
      new URLSearchParams(location.search).get('host') ||
      window.SHOPIFY_DEV_HOST;
    const apiKey =
      Storage.get(Constants.API_KEY) ||
      new URLSearchParams(location.search).get('clientId');

    window.SHOPIFY_DEV_HOST = host;
    return {
      host,
      apiKey,
      forceRedirect: false, //process.env.REACT_APP_FORCE_REDIRECT === 'true',
    };
  });
  return appBridgeConfig;
}
