import { Card, Layout, Page, Text } from '@shopify/polaris';
import React from 'react';
import styled from '@emotion/styled';
import { LoadingSpinner } from './LoadingSpinner';

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  padding: 80px;
  & > * {
    margin-bottom: 10px;
  }
`;

export default function LoadingPage({ heading, description }) {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <CardStyled>
              <LoadingSpinner />
              <Text alignment='center' as='h1' variant='headingMd'>
                {heading}
              </Text>
              <Text alignment='center'>{description}</Text>
            </CardStyled>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
