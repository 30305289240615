import { AuthenticatedApi } from '../utils/Api';

export const AnalyticsService = {
  async trackAnalytics(eventName, distinct_id, body) {
    await AuthenticatedApi.post('/api/1.0/analytics-service/track', {
      eventName,
      distinct_id,
      data: body,
    });
  },
  async setUserProfile(distinct_id, email, host, data) {
    await AuthenticatedApi.post('/api/1.0/analytics-service/set-user-profile', {
      distinct_id,
      email,
      host,
      data,
    });
  },
};
