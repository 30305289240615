import * as Sentry from '@sentry/react';

export const accountsReducerInitialState = {
  loadingAccounts: false,
  accountingAccount: null,
  retailAccount: null,
  shopifyAccount: null,
  integration: null,
  qbdAccount: null,
};
export function accountsReducer(state, action) {
  switch (action.type) {
    case 'accounts/SET_ACCOUNTING_ACCOUNT':
      Sentry.setContext('accountingAccount', action.payload.accountingAccount);
      return {
        ...state,
        accountingAccount: action.payload.accountingAccount,
      };
    case 'accounts/SET_RETAIL_ACCOUNT':
      Sentry.setContext('retailAccount', action.payload.retailAccount);
      return {
        ...state,
        retailAccount: action.payload.retailAccount,
      };
    case 'accounts/SET_SHOPIFY_ACCOUNT':
      Sentry.setContext('shopifyAccount', action.payload.shopifyAccount);
      return {
        ...state,
        shopifyAccount: action.payload.shopifyAccount,
      };
    case 'accounts/SET_QBD_ACCOUNT':
      Sentry.setContext('qbdAccount', action.payload.qbdAccount);
      return {
        ...state,
        qbdAccount: action.payload.qbdAccount,
      };
    case 'accounts/SET_LOADING_ACCOUNTS':
      return {
        ...state,
        loadingAccounts: action.payload.loadingAccounts,
      };
    case 'accounts/SET_INTEGRATION':
      Sentry.setContext('retailAccount', action.payload.integration);
      return {
        ...state,
        integration: action.payload.integration,
      };
    default:
      return state;
  }
}

const setLoadingAccounts = loading => {
  return {
    type: 'accounts/SET_LOADING_ACCOUNTS',
    payload: {
      loadingAccounts: loading,
    },
  };
};

const setAccountingAccount = accountingAccount => {
  return {
    type: 'accounts/SET_ACCOUNTING_ACCOUNT',
    payload: {
      accountingAccount,
    },
  };
};

const setRetailAccount = retailAccount => {
  return {
    type: 'accounts/SET_RETAIL_ACCOUNT',
    payload: {
      retailAccount,
    },
  };
};

const setShopifyAccount = shopifyAccount => {
  return {
    type: 'accounts/SET_SHOPIFY_ACCOUNT',
    payload: {
      shopifyAccount,
    },
  };
};

const setQBDAccount = qbdAccount => {
  return {
    type: 'accounts/SET_QBD_ACCOUNT',
    payload: {
      qbdAccount,
    },
  };
};

const setIntegration = integration => {
  return {
    type: 'accounts/SET_INTEGRATION',
    payload: {
      integration,
    },
  };
};

export const accountActions = {
  setLoadingAccounts,
  setAccountingAccount,
  setRetailAccount,
  setShopifyAccount,
  setIntegration,
  setQBDAccount,
};

export const accountFactory = {
  accountingAccount: data => {
    return Object.assign(
      {},
      {
        password: '12345',
        xmlFileData: '<xml><test></test></xml>',
        accountId: 'accountId',
      },
      data
    );
  },
};
