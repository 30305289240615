import { AuthenticatedApi, Api } from '../utils/Api';
import * as Constants from '../utils/Constants';
import { Storage } from '../utils/Storage';

export const AuthService = {
  async getAuthenticatedUser() {
    if (!(await Storage.get(Constants.SP_AUTH_TOKEN))) {
      throw new Error('Auth token not found');
    }
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/user/get-authenticated'
    );
    return data;
  },
  async loginWithOneTimeToken(oneTimeLoginToken) {
    const { data } = await Api.post(
      '/api/1.0/auth/login/with-one-time-login-token',
      {
        oneTimeLoginToken,
      }
    );
    return data;
  },
};
