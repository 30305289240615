/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React from 'react';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { StateProvider } from './hooks/useGlobalState';
import PolarisProvider from './providers/PolarisProvider';
import { ErrorBoundary } from './components/ErrorBoundary';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  release: process.env.REACT_APP_SENTRY_RELEASE || '0.0.0',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  environment:
    process.env.REACT_APP_ENV ||
    (location.hostname.includes('staging') ? 'staging' : 'production'),
  debug: false,
});

Sentry.setUser({ id: new URLSearchParams(location.search).get('shop') });

const Component = (
  <StateProvider>
    <BrowserRouter>
      <PolarisProvider>
        <ErrorBoundary>
          <Global
            styles={css`
              [data-rmiz-modal-overlay='visible'] {
                background-color: rgba(0, 0, 0, 0.4) !important;
              }
            `}
          ></Global>
          <App />
        </ErrorBoundary>
      </PolarisProvider>
    </BrowserRouter>
  </StateProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(Component);
