import { AuthenticatedApi } from '../utils/Api';

export const RetailService = {
  async getAccounts() {
    await new Promise(resolve => setTimeout(resolve, 4000));
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/retailpos-account/account'
    );
    return data;
  },
  async getShopifyAccount(host) {
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/shopify-stocky-connector/get-integration?host=' + host
    );
    return data;
  },
  async getApiKey() {
    const { data } = await AuthenticatedApi.get(
      '/oauth/shopify-stocky-connector/get-application-client'
    );
    return data;
  },
  async getLocations(host) {
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/shopify-stocky-connector/get-locations?host=' + host
    );
    return data;
  },
  async resyncLocations(shop) {
    const { data } = await AuthenticatedApi.post(
      `/api/1.0/shopify-stocky-connector/resync/location`,
      {
        shop,
      }
    );
    return data;
  },
  async getTaxes(host) {
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/shopify-stocky-connector/get-taxes',
      {
        params: {
          host,
        },
      }
    );
    return data;
  },
  async setStockyApiKey(shop, apiKey) {
    const { data } = await AuthenticatedApi.post(
      '/api/1.0/shopify-stocky-connector/add-stocky-api-key',
      {
        shop,
        stockyApiKey: apiKey,
      }
    );
    // eslint-disable-next-line no-console
    if (data?.success === false) return { error: true };
    return data;
  },

  async getPaymentTermtemplate(host) {
    const { data } = await AuthenticatedApi.get(
      '/api/1.0/shopify-stocky-connector/get-payment-term-template?host=' + host
    );
    return data;
  },
  async resyncPaymentTerm(shop) {
    const { data } = await AuthenticatedApi.post(
      `/api/1.0/shopify-stocky-connector/resync/payment-term-template`,
      {
        shop,
      }
    );
    return data;
  },
  async enableB2BFeature({ shop, enableB2BFeature }) {
    const { data } = await AuthenticatedApi.post(
      `/api/1.0/shopify-stocky-connector/enable/b2b-feature`,
      {
        shop,
        enableB2BFeature,
      }
    );
    return data;
  },
};
